import React, { useState, useMemo, useEffect, Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
// import Applicationstack from "./routes/Applicationstack";
import { AuthContext, UserRoleAccessContext } from "./context/Appcontext";
// import Authstack from "./routes/Authstack";
import { AUTH } from "./services/Authservice";
import { SERVICE } from "./services/Baseservice";
// import Webstock from "./routes/Webroutes";
import axios from "axios";
import Loading from "./routes/Loading";

const Applicationstack = React.lazy(() => import("./routes/Applicationstack"));
const Authstack = React.lazy(() => import("./routes/Authstack"));

function App() {
  const [auth, setAuth] = useState({
    loader: false,
    loginState: false,
    APIToken: "",
    loginuserid: "",
    loginusercode: "",
  });
  const [isUserRoleAccess, setIsUserRoleAccess] = useState({});
  const [allProjects, setAllprojects] = useState([]);
  const [alltaskLimit, setalltaskLimit] = useState([]);
  const [allTasks, setallTasks] = useState([]);
  const [allUsersLimit, setallUsersLimit] = useState([]);
  const [isUserRoleCompare, setIsUserRoleCompare] = useState([]);
  const [qrImage, setQrImage] = useState("");
  const [isAssignBranch, setIsAssignBranch] = useState([]);
  const [allUsersData, setAllUsersData] = useState([]);
  const [allCompany, setAllCompany] = useState([]);
  const [allBranch, setAllBranch] = useState([]);
  const [allUnit, setAllUnit] = useState([]);
  const [allTeam, setAllTeam] = useState([]);
  const [allfloor, setAllFloor] = useState([]);
  const [allarea, setAllArea] = useState([]);
  const [allareagrouping, setAllAreagrouping] = useState([]);
  const [alllocation, setAllLocation] = useState([]);
  const [alllocationgrouping, setAllLocationgrouping] = useState([]);
  const [alldepartment, setAllDepartment] = useState([]);
  const [alldesignation, setAllDesignation] = useState([]);

  const authContextData = useMemo(() => {
    return { auth, setAuth, qrImage, setQrImage };
  }, [
    auth,
    allProjects,
    isUserRoleCompare,
    alltaskLimit,
    allTasks,
    isAssignBranch,
    isUserRoleAccess,
    allUsersLimit,
    qrImage,
  ]);
  const applicationContextData = useMemo(() => {
    return {
      allUsersData,
      setAllUnit,
      setAllTeam,
      allBranch,
      setAllBranch,
      allTeam,
      setAllCompany,
      setAllUsersData,
      allUnit,
      allCompany,
      isUserRoleAccess,
      setIsUserRoleAccess,
      isAssignBranch,
      setIsAssignBranch,
      isUserRoleCompare,
      setIsUserRoleCompare,
      allProjects,
      setAllprojects,
      allUsersLimit,
      setallUsersLimit,
      alltaskLimit,
      setalltaskLimit,
      allTasks,
      setallTasks,
      allfloor,
      setAllFloor,
      allarea,
      setAllArea,
      allareagrouping,
      setAllAreagrouping,
      alllocation,
      setAllLocation,
      alllocationgrouping,
      setAllLocationgrouping,
      alldepartment,
      setAllDepartment,
      alldesignation,
      setAllDesignation,
    };
  }, [
    auth,
    allUsersData,
    allBranch,
    allTeam,
    allUnit,
    allfloor,
    allCompany,
    allarea,
    allareagrouping,
    alllocation,
    alllocationgrouping,
    alldepartment,
    alldesignation,
    allProjects,
    isUserRoleCompare,
    alltaskLimit,
    allTasks,
    isUserRoleAccess,
    isAssignBranch,
    allUsersLimit,
  ]);

  var today = new Date();
  var todayDate = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
  var yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;

  var todayDateFormat = `${dd}/${mm}/${yyyy}`;

  // Get yesterday's date
  var yesterday = new Date(todayDate);
  yesterday.setDate(todayDate.getDate() - 1);
  var ddp = String(yesterday.getDate()).padStart(2, "0");
  var mmp = String(yesterday.getMonth() + 1).padStart(2, "0"); // January is 0!
  var yyyyp = yesterday.getFullYear();

  var yesterdayDate = yyyyp + "-" + mmp + "-" + ddp;
  var yesterdayDateFormat = `${ddp}/${mmp}/${yyyyp}`;


  useEffect(() => {
    isCheckUserLogin();
  }, []);

  const isCurrentTimeInShift = async (shifts) => {
    if (shifts) {
      const now = new Date();
      const currentHour = now.getHours();
      const currentMinute = now.getMinutes();
      const currentInMinutes = currentHour * 60 + currentMinute;

      for (let shift of shifts) {
        if (shift?.shift === "Week Off") {
          continue;
        }
        const [startTime, endTime] = shift?.shift?.split("to");

        // Function to convert time string to hour and minute

        const parseTime = (time) => {
          if (!time) {
            // Return a default value or null
            return { hours: 0, minutes: 0 };
          }

          const match = time.match(/(\d+):(\d+)(AM|PM)/);
          if (!match) {
            // Return a default value or null
            return { hours: 0, minutes: 0 };
          }

          let [hours, minutes] = match.slice(1, 3).map(Number);
          const period = match[3];

          if (period === "PM" && hours !== 12) hours += 12;
          if (period === "AM" && hours === 12) hours = 0;

          return { hours, minutes };
        };

        const start = parseTime(startTime);
        const end = parseTime(endTime);

        // Check if the shift starts in PM and ends in AM
        if (start.hours >= 12 && end.hours < 12) {
          // Calculate the end time in minutes
          const endInMinutes = end.hours * 60 + end.minutes;

          // Check if current time falls within 12:00 AM to end time
          if (currentInMinutes < endInMinutes) {
            return true;
          }
        }
      }
      return false;
    } else {
      return false;
    }
  };
  const isCheckUserLogin = async () => {
    setAuth({ ...auth, loader: true });
    let getApiToken = localStorage.getItem("APIToken");
    let getLoginUserid = localStorage.getItem("LoginUserId");
    let getLoginUserRole = localStorage.getItem("LoginUserrole");
    let getLoginUserCode = localStorage.getItem("LoginUsercode");
    const outputArray = getLoginUserRole?.split(",");
    let startMonthDate = new Date(yesterdayDate);
    let endMonthDate = new Date(today);

    const daysArray = [];
    while (startMonthDate <= endMonthDate) {
      const formattedDate = `${String(startMonthDate.getDate()).padStart(
        2,
        "0"
      )}/${String(startMonthDate.getMonth() + 1).padStart(
        2,
        "0"
      )}/${startMonthDate.getFullYear()}`;
      const dayName = startMonthDate.toLocaleDateString("en-US", {
        weekday: "long",
      });
      const dayCount = startMonthDate.getDate();
      const shiftMode = "Main Shift";

      daysArray.push({ formattedDate, dayName, dayCount, shiftMode });

      // Move to the next day
      startMonthDate.setDate(startMonthDate.getDate() + 1);
    }
    if (getApiToken) {
      try {
        const [
          loginuserdata,
          userrole,
          documents,
          loginusershift,
          allusersdata,
          // allcompany,
          allbranch,
          allunit,
          allteam,
          allfloor,
          allarea,
          allareagrouping,
          alllocation,
          alllocationgrouping,
          alldepartment,
          alldesignation,
        ] = await Promise.all([
          axios.get(`${AUTH.GETUSER}/${getLoginUserid}`, {
            headers: {
              Authorization: `Bearer ${getApiToken}`,
            },
          }),
          axios.post(AUTH.GETAUTHROLE, {
            userrole: outputArray,
          }),
          axios.post(AUTH.GETDOCUMENTS, {
            commonid: getLoginUserid,
          }),

          // axios.get(SERVICE.COMPANY, {
          //   headers: {
          //     Authorization: `Bearer ${getApiToken}`,
          //   },
          // }),
        ]);

        console.log(loginusershift?.data?.finaluser);

        const yesrtedayShifts = loginusershift?.data?.finaluser?.filter(
          (data) => data?.formattedDate === yesterdayDateFormat
        );
        const todayShifts = loginusershift?.data?.finaluser?.filter(
          (data) => data?.formattedDate === todayDateFormat
        );

        const isInYesterdayShift = await isCurrentTimeInShift(
          yesrtedayShifts?.length > 0
            ? [yesrtedayShifts[yesrtedayShifts?.length - 1]]
            : []
        );

        const finalShift = isInYesterdayShift ? yesrtedayShifts : todayShifts;

        console.log(isInYesterdayShift, "isInYesterdayShift");
        console.log(finalShift, "finalShift");


        const secondshifttimespl =
          finalShift?.length > 1 ? finalShift[1]?.shift?.split("to") : "";
        let managerassign = [];
        // await allcompany?.data?.companies.forEach((comp) => {
        //   allbranch?.data?.branch
        //     .filter((br) => br.company === comp.name)
        //     .forEach((br) => {
        //       allunit?.data?.units
        //         .filter((un) => un.branch === br.name)
        //         .forEach((un) => {
        //           managerassign.push({
        //             company: comp.name,
        //             companycode: comp.code,
        //             branch: br.name,
        //             branchcode: br.code,
        //             branchemail: br.email,
        //             branchaddress: br.address,
        //             branchstate: br.state,
        //             branchcity: br.city,
        //             branchcountry: br.country,
        //             branchpincode: br.pincode,
        //             unit: un.name,
        //             unitcode: un.code,
        //           });
        //         });
        //     });
        // });

        if (documents && documents.data) {
          setIsUserRoleAccess({
            ...loginuserdata?.data?.suser,
            files: documents?.data?.semployeedocument?.files,
            profileimage: documents?.data?.semployeedocument?.profileimage,
            userdayshift: finalShift,
            mainshiftname: "",
            issecondshift: finalShift?.length > 1 ? true : false,
            secondshiftname: "",
            secondshifttiming:
              finalShift?.length > 1
                ? secondshifttimespl[0] + "-" + secondshifttimespl[1]
                : "",
          });
        } else {
          setIsUserRoleAccess({
            ...loginuserdata?.data?.suser,
            files: [],
            profileimage: "",
            userdayshift: finalShift,
            mainshiftname: "",
            issecondshift: finalShift?.length > 1 ? true : false,
            secondshiftname: "",
            secondshifttiming:
              finalShift?.length > 1
                ? secondshifttimespl[0] + "-" + secondshifttimespl[1]
                : "",
          });
        }
        setAllUsersData(allusersdata?.data?.usersstatus);
        // setAllCompany(allcompany?.data?.companies);
        setAllBranch(allbranch?.data?.branch);
        setAllUnit(allunit?.data?.units);
        setAllTeam(allteam?.data?.teamsdetails);
        setAllFloor(allfloor?.data?.floors);
        setAllArea(allarea?.data?.areas);
        setAllAreagrouping(allareagrouping?.data?.areagroupings);
        setAllLocation(alllocation?.data?.locationdetails);
        setAllLocationgrouping(alllocationgrouping?.data?.locationgroupings);
        setAllDepartment(alldepartment?.data?.departmentdetails);
        setAllDesignation(alldesignation?.data?.designation);
        setIsUserRoleCompare(userrole?.data?.result);

        setAuth((prevAuth) => {
          return {
            ...prevAuth,
            loader: false,
            loginState: true,
            APIToken: getApiToken,
            loginuserid: getLoginUserid,
            loginuserrole: getLoginUserRole,
            loginusercode: getLoginUserCode,
          };
        });

        axios
          .get(AUTH.ALLUSERLIMIT)
          .then((response) => setallUsersLimit(response.data.users));
      } catch (err) {
        console.log(err)
        setAuth({ ...auth, loader: false, loginState: false });
        const messages = err?.response?.data?.message;
        if (messages) {
          console.log(messages);
        } else {
          console.log("Something went wrong check connection!");
        }
      }
    } else {
      setAuth({ ...auth, loader: false, loginState: false });
      console.log("noapi");
    }
  };

  return (
    <>
      {/* <Webstock /> */}
      <div>
        <AuthContext.Provider value={authContextData}>
          <UserRoleAccessContext.Provider value={applicationContextData}>
            <BrowserRouter basename={process.env.PUBLIC_URL}>
              {!auth.loginState ? (
                <Suspense fallback={<Loading />}>
                  <Authstack />
                </Suspense>
              ) : (
                <Suspense fallback={<Loading />}>
                  {" "}
                  <Applicationstack />
                </Suspense>
              )}
            </BrowserRouter>
          </UserRoleAccessContext.Provider>
        </AuthContext.Provider>
      </div>
    </>
  );
}

export default App;
