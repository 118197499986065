// export const BASE_URL = "http://192.168.85.169:7000";
// export const BASE_URL = "http://192.168.85.100:7003";
// export const BASE_URL = "http://localhost:7002";
// export const BASE_URL = "http://hilife.ai";
// export const BASE_URL = "http://anubhuthi.org";
// export const BASE_URL = "http://hilifeai.in";
// export const BASE_URL = "http://hrms.ttsbs.com";
// export const BASE_URL = "http://uniqzcare.in";
export const BASE_URL = "https://rotarykattur.org";
// 4d11b755002d7aef786deefcdbfc287e-8b85b3c7-f04f-48d5-9d21-ca1bb9230df9
export const AUTH = {
  LOGIN: `${BASE_URL}/api/authlog`,
  GETUSER: `${BASE_URL}/api/auth`,
  GETAUTHROLE: `${BASE_URL}/api/authroles`,
  LOGOUT: `${BASE_URL}/api/authout`,
  PROJECTLIMIT: `${BASE_URL}/api/projectslimit`,
  TASKSLIMIT: `${BASE_URL}/api/allfiltertask`,
  ALLTASKS: `${BASE_URL}/api/alltasktime`,
  ALLUSERLIMIT: `${BASE_URL}/api/usersalllimit`,
  VERIFYTWOFA: `${BASE_URL}/api/verifytwofa`,
  VERIFYVIEWPASSWORD: `${BASE_URL}/api/verification/viewpassword`,
  GETDOCUMENTS: `${BASE_URL}/api/employeedocumentcommonid`,
};
